import { Box, CircularProgress, Typography } from "@mui/material";
import { Logo } from "static/images";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer, useSessionStorageState } from "@toolpad/core";
import { getInfoUser } from "utils/services";
import { useGlobalState } from "components/GlobalProvider";

interface ExtensionSyncProps {
  onFinish?: (success: boolean) => void;
}

export const ExtensionSync: React.FC<ExtensionSyncProps> = ({ onFinish }) => {
  const { setUser } = useGlobalState();
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useSessionStorageState("access_token");


  useEffect(() => {
    const syncUser = async (token: string) => {
      try {
        const userInfo = await getInfoUser();
        setUser(userInfo);
        sessionStorage.setItem("user", JSON.stringify(userInfo));
        if (onFinish) onFinish(true);
        navigate("/dashboard");
      } catch (error) {
        console.error("Erro ao sincronizar usuário:", error);
        sessionStorage.clear();
        if (onFinish) onFinish(false);
        navigate("/login");
      }
    };

    const handleToken = async (token: string) => {
      // Verifica se o token existe na URL
      const params = new URLSearchParams(window.location.hash.slice(2));
      const urlToken = params.get("token") || token;

      // Caso 2: Token na URL
      if (urlToken) {
        sessionStorage.setItem("access_token", urlToken);
        setAccessToken(urlToken);
        await syncUser(urlToken);
        return;
      }

      // Caso 3: Já logado
      if (accessToken) {
        await syncUser(accessToken);
        return;
      }

      // Caso 4: Nenhum token disponível
      navigate("/login");
    };

    window.resumeAuth = handleToken;

  }, [accessToken, setAccessToken, setUser, navigate, onFinish]);

  return <PageContainer sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', margin: '32px 0' }} >
      <img height="33px" width="auto" src={Logo} alt='Logo Resume Tudo' style={{ marginBottom: 32 }} />
      <CircularProgress />
      <Typography variant="body2" color="text.secondary" style={{ margin: '18px 0 0' }}>Carregando seus dados</Typography>
    </Box>
  </PageContainer>
    ;
  };
