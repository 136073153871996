import React from 'react';
import { createTheme } from '@mui/material/styles';
import { AppProvider, Branding, type Navigation } from '@toolpad/core/AppProvider';
import { Logo } from 'static/images';
import { Tag, Settings, PaymentRounded } from '@mui/icons-material';
import { Outlet } from 'react-router-dom';

const NAVIGATION: Navigation = [
    {
        segment: '#/dashboard',
        title: 'Reuniões',
        icon: <Tag />,
        kind: 'page'
    },
    // {
    //     segment: '#/convidar',
    //     title: 'Convide e Ganhe',
    //     icon: <Cake />,
    // },
    {
        segment: '#/dashboard/configuracoes',
        title: 'Configurações',
        icon: <Settings />,
        children: [
            {
                segment: 'cobranca',
                title: 'Cobrança',
                icon: <PaymentRounded />,
            },
            // {
            //     segment: 'equipe',
            //     title: 'Equipe',
            //     icon: <Groups2Rounded />,
            // },
        ],
    },
    {
        kind: 'divider',
    },
];

const BRANDING: Branding = { logo: <img height="33px" width="auto" src={Logo} alt='ResumeTudo' />, title: '' }

const themeResumeTudo = createTheme({
    cssVariables: {
        colorSchemeSelector: 'data-toolpad-color-scheme',
    },
    colorSchemes: { light: true, dark: false },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
});

interface DashProps {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window?: () => Window;
}

export default function DashboardResumeTudo(props: DashProps) {

    return (
        <AppProvider
            navigation={NAVIGATION}
            theme={themeResumeTudo}
            branding={BRANDING}
        >
            <Outlet />
        </AppProvider>
    );
}
