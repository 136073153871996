import { CircularProgress } from "@mui/material";
import React from "react";

const Loading = () => {
    return <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <CircularProgress/>
        {/* <Skeleton animation="wave" variant="rectangular" style={{ height: '186px', width: '275px' }} />
        <Skeleton animation="wave" variant="rectangular" style={{ height: '186px', width: '275px' }} />
        <Skeleton animation="wave" variant="rectangular" style={{ height: '186px', width: '275px' }} /> */}
    </div>
}

export default Loading