import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { PageContainer } from '@toolpad/core/PageContainer';
import { Avatar, Box, Button, Menu, MenuItem } from '@mui/material';
import { useSessionStorageState } from '@toolpad/core';
import { googleLogout } from '@react-oauth/google';
import { UserProfile } from 'utils/types';
import { useGlobalState } from 'components/GlobalProvider';

export default function Layout() {
  const { user, setUser } = useGlobalState();

  return (

    <DashboardLayout slotProps={
      {
        toolbarAccount: {
          localeText: { iconButtonAriaLabel: 'test', signInLabel: 'Logar', signOutLabel: 'Sair' },
          slotProps: { signInButton: <></>, signOutButton: <>Sair</> }
        },
        toolbarActions: <>toolbarActions</>
      }
    }
      slots={{
        sidebarFooter: () => <AccountOptions imgUrl="/static/images/avatar/1.jpg" user={user} />

      }}
    >
      <PageContainer>
        <Outlet />
      </PageContainer>
    </DashboardLayout>
  );
}

interface AccountOptionsProps {
  user: UserProfile | undefined;
  imgUrl: string
}

const AccountOptions = (props: AccountOptionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [, setAccessToken] = useSessionStorageState('access_token', null)
  const navigate = useNavigate();


  const logout = () => {
    setAnchorEl(null);
    googleLogout()
    setAccessToken(null)
    sessionStorage.clear();
    navigate("/login");
  };


  return (
    <Box sx={{ width: '100%' }}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ width: '100%' }}
      >
        <Avatar alt={props.user?.name} src={props.imgUrl} sx={{ marginRight: '8px' }} />
        {props.user?.name}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* <MenuItem onClick={contactUs} >Fale Conosco</MenuItem> */}
        <MenuItem onClick={logout}>Sair</MenuItem>
      </Menu>
    </Box >
  );
}