import React, { FC, Fragment } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Meeting } from 'utils/types';
import { Delete, Google, Microsoft, Share } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';


interface MeetingCardProps {
  meeting: Meeting
  isBlocked: boolean
}

const MeetingCard: FC<MeetingCardProps> = ({ meeting, isBlocked }) => {
  const navigate = useNavigate();
  // Limite de caracteres que serão exibidos
  const maxLength = 20
  const truncatedTitle = meeting.title.length > maxLength ? meeting.title.slice(0, maxLength) + '...' : meeting.title;

  // Limite de participantes que serão exibidos
  const maxVisibleParticipants = 3;
  const visibleParticipants = meeting.participants.slice(0, maxVisibleParticipants);
  const remainingParticipants = meeting.participants.length - maxVisibleParticipants;

  const handleCardClick = () => {
    if (!isBlocked) { navigate(`/dashboard/${meeting.id}`); }
  };

  return (
    <Card sx={{
      width: 275,
      textAlign: 'left',
    }} variant="outlined" title={meeting.title}>
      <Fragment>
        <CardContent sx={{
          opacity: isBlocked ? 0.5 : 1,
          pointerEvents: isBlocked ? 'none' : 'auto',
          cursor: isBlocked ? 'not-allowed' : 'pointer'
        }} onClick={handleCardClick}>
          <Typography variant="h6">
            {truncatedTitle}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {visibleParticipants.join(', ')}
            {remainingParticipants > 0 && (
              <strong style={{ paddingLeft: 4 }}>
                +{remainingParticipants}
              </strong>
            )}
          </Typography>
          <Typography variant="body2" margin={'4px 0'}>
            {meeting.date}
          </Typography>
        </CardContent>
        <CardActions sx={{ position: 'relative' }}
        >
          {isBlocked ? (
            <Button
              variant="contained"
              color="primary"
              sx={{ margin: 'auto' }}
              onClick={() => navigate('/configuracoes/cobranca')} // Redireciona para a tela de planos
            >
              DESBLOQUEAR
            </Button>
          ) : (
            <CardActions sx={{ marginLeft: 'auto' }}>
              <IconButton title={meeting.platform}>
                {meeting.platform === 'Google Meet' ? <Google /> : <Microsoft />}
              </IconButton>
              <IconButton title='compartilhar'>
                <Share />
              </IconButton>
              <IconButton title='excluir'>
                <Delete />
              </IconButton>
            </CardActions>
          )}
        </CardActions>
      </Fragment>
    </Card>
  );
}

export default MeetingCard;