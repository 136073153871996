import { ExtensionSync } from "components/ExtensionSync";
import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";


export const RedirectToDashboard: React.FC = () => {

  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const location = useLocation();

  const handleSyncFinish = (success: boolean) => {
    setAuthenticated(success);
    setLoading(false);
  };

  if (loading) {
    // Exibe tela de carregamento durante a sincronização
    return <ExtensionSync onFinish={handleSyncFinish} />;
  }

  if (!authenticated) {
    // Redireciona para login se não autenticado
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Navigate to="/dashboard" replace />;
};
