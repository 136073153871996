import React, { FC } from "react"
import { PageContainer } from "@toolpad/core"

interface ConvidarProps {

}

const Convidar: FC<ConvidarProps> = () => {
    return <PageContainer
        title="Convidar"
        breadcrumbs={[]}
        style={{ maxWidth: '905px' }}>

    </PageContainer>
}

export default Convidar;