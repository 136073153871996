import React, { FC, } from "react";
import { Outlet } from "react-router-dom";

interface ReunioesProps {
}


const Reunioes: FC<ReunioesProps> = () => {
  return <>
    <Outlet />
  </>
}

export default Reunioes