import React, { useEffect, useState } from "react";
import { Box, Divider, Grid2 } from "@mui/material"
import Loading from "components/Loading"
import MeetingCard from "components/MeetingCard"
import SearchField from "components/SearchField"
import { MeetingList } from "utils/types";
import { getMeetings } from "utils/services";
import { PageContainer, useSessionStorageState } from "@toolpad/core";
import { LoadingButton } from "@mui/lab";
import { useGlobalState } from "components/GlobalProvider";

const ReuniaoList = () => {
    const { user, setUser } = useGlobalState();
    const [meetings, setMeetings] = useState<MeetingList | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const fetchMeetings = async (page: number) => {
        // if (loading || isFetching) return; // Previne múltiplos disparos

        setLoading(true);
        try {
            const data = await getMeetings(page);
            setMeetings((prevMeetings) => ({
                ...prevMeetings,
                items: [
                    ...(prevMeetings?.items || []),
                    ...data.items
                ],
                total: data.total,
                page: data.page,
                page_size: data.page_size,
                total_pages: data.total_pages
            }));
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    };

    const fetchMoreMeetings = async () => {
        // if (isFetching || loading) return;

        setIsFetching(true);
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        try {
            const data = await getMeetings(currentPage + 1);
            setMeetings((prevMeetings) => ({
                ...prevMeetings,
                items: [
                    ...(prevMeetings?.items || []),
                    ...data.items
                ],
                total: data.total,
                page: data.page,
                page_size: data.page_size,
                total_pages: data.total_pages
            }));
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    };

    useEffect(() => {
        fetchMeetings(currentPage);
    }, []);

    const isLastPage = (meetings?.page ?? 0) >= (meetings?.total_pages ?? 0);

    return (
        <PageContainer
            title={"Minhas Reuniões"}
            style={{ maxWidth: '1280px', textAlign: 'center' }}
        >
            <Box>
                {/* <SearchField onSearch={handleSearch} /> */}
                <Divider style={{ margin: '12px 0' }} />
                <Grid2 container spacing={2}>
                    {!loading && meetings?.items.map((meeting, index) => {
                        const isBlocked =
                            (user?.subscription === "free" && index > 0) ||
                            (user?.subscription === "basic" && index >= 10); 
                            return <MeetingCard isBlocked={isBlocked} key={meeting.id} meeting={meeting} />
                    })}
                    {loading && <Loading />}
                </Grid2>
                {!isLastPage && meetings && meetings.page < meetings.total_pages && (
                    <LoadingButton
                        loading={isFetching}
                        variant="outlined"
                        onClick={fetchMoreMeetings}
                        disabled={isFetching}
                        sx={{ marginTop: '24px' }}
                    >
                        Carregar mais
                    </LoadingButton>
                )}
            </Box>
        </PageContainer>
    );
};

export default ReuniaoList;
