import React from 'react';
import { Box, Typography } from '@mui/material';
import { PageContainer } from '@toolpad/core';
import GoogleLoginButton from './GoogleLink';
import { Logo } from 'static/images';

export default function OAuthSignInPage() {

    return (
        <PageContainer sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', margin: '32px 0' }} >
                <img height="33px" width="auto" src={Logo} alt='Logo Resume Tudo' />
                <Typography variant="body2" color="text.secondary" style={{ margin: '18px 0 0' }}>Entre para obter os resumos e transcrições das reuniões</Typography>
            </Box>
            <GoogleLoginButton />
            {/* <Divider sx={{ margin: '24px 0' }}> <Typography variant="body2" color="text.secondary" >Ou receba um link mágico</Typography></Divider>
            <MagicLink /> */}
        </PageContainer>
    );
}