import React, {useEffect, useState } from "react";
import "./styles.scss";

interface InputPropos {
    onBlur: (value: string) => void,
    initialValue: string
    placeholder?: string,
}

export const Input = ({onBlur, initialValue, placeholder}: InputPropos) => {
    const [value, setValue] = useState("")

    useEffect(() => {
        console.log('Input')
        setValue(initialValue)
    }, [initialValue])
    
    return (
        <input value={value} onChange={(event) => setValue(event.target.value)} className="resume-input" onBlur={(event) => onBlur(event.target.value)} placeholder={placeholder} />
    )
}