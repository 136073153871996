import React from 'react';
import { 
  Typography, 
  Button, 
  Container,
  styled
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NotFoundImg } from 'static/images';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: theme.spacing(2),
  backgroundColor: '#f5f5f5',
}));

const StyledImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  height: 'auto',
  marginBottom: theme.spacing(4),
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/login');
  };

  return (
    <StyledContainer maxWidth="sm">
      <StyledImage
        src={NotFoundImg} 
        alt="404 Not Found" 
      />
      <StyledTitle variant="h4">
        Oops! Página Não Encontrada
      </StyledTitle>
      <StyledSubtitle variant="body1">
        A página que você procura pode ter sido removida, ter seu nome alterado ou estar temporariamente indisponível.
      </StyledSubtitle>
      <Button variant="contained" color="primary" onClick={handleGoHome}>
        Ir para Homepage
      </Button>
    </StyledContainer>
  );
};

export default NotFound;

