import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { UserProfile } from "utils/types";

// 1. Criação do contexto
interface GlobalState {
  user: UserProfile | undefined;
  setUser: React.Dispatch<React.SetStateAction<UserProfile | undefined>>;
}

const GlobalContext = createContext<GlobalState | undefined>(undefined);

// 2. Provider do contexto
export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserProfile | undefined>(undefined);
  
  useEffect(() => {
    // Restaurar estado do sessionStorage
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <GlobalContext.Provider value={{ user, setUser }}>
      {children}
    </GlobalContext.Provider>
  );
};

// 3. Hook para usar o estado global
export const useGlobalState = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalState deve ser usado dentro de um GlobalProvider");
  }
  return context;
};