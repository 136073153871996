import React, { FC } from "react"
import { PageContainer } from "@toolpad/core"

interface EquipeProps {

}

const Equipe: FC<EquipeProps> = () => {
    return <PageContainer
        title="Equipe"
        breadcrumbs={[]}
        style={{ maxWidth: '905px' }}>

    </PageContainer>
}

export default Equipe;