import React, { FC, useState } from "react"
import { PageContainer } from "@toolpad/core"
import { Alert, Box, Button, Card, CardContent, Chip, CircularProgress, LinearProgress, Modal, Stack, Typography } from "@mui/material";
import './styles.scss';
import { Check } from "@mui/icons-material";
import { useGlobalState } from "components/GlobalProvider";
import { getPaymentLink } from "utils/services";
interface CobrancaProps {

}

interface PlanProps {
    planName: string
    type: 'free' | 'basic' | 'pro' | 'enterprise'
    isRecommended?: boolean
    isSelected?: boolean
    isContracted?: boolean
    countTransc?: number
    countAsk?: number
    limitTransc?: number
    limitAsk?: number
}

function PlanCard(props: PlanProps) {
    // const progressTransc = (props.countTransc && props.limitTransc) ? props.countTransc / props.limitTransc * 100 : 0;
    // const progressAsk = (props.countAsk && props.limitAsk) ? props.countAsk / props.limitAsk * 100 : 0;
    const [open, setOpen] = useState(false)
    const generateLink = (planId: string) => {
        setOpen(true)
        getPaymentLink(planId).then((response) => {
            setOpen(false)
            window.open(response.data.payment_link, '_blank')
        })
    }
    return <Card className={`${props.isRecommended && 'recommended'} ${props.isContracted && 'contracted'}`} sx={{ width: '100%' }}>
        <CardContent>
            {props.isRecommended && !props.isContracted && <Chip label='Recomendado' color="primary" />}
            {props.isContracted && <Chip label='Contratado' color="success" />}
            <Typography gutterBottom sx={{ fontSize: 28, fontWeight: '500' }}>
                {props.planName}
            </Typography>

            {props.type === 'free' &&
                <Stack className="free">
                    <Typography sx={{ fontSize: 48, fontWeight: '500' }}>R$0,00</Typography>
                    <Typography gutterBottom sx={{ fontSize: 16, fontWeight: '400', color: 'text.secondary' }}></Typography>
                    <Typography gutterBottom sx={{ fontSize: 16, fontWeight: '500' }}>Para começar. Sempre Grátis.</Typography>
                    <Typography gutterBottom display={'flex'}><Check sx={{ marginRight: 1 }} />Transcrições ilimitadas</Typography>
                    <Typography gutterBottom display={'flex'}><Check sx={{ marginRight: 1 }} />Visualize 1 reunião /mês</Typography>
                    <Typography gutterBottom display={'flex'}><Check sx={{ marginRight: 1 }} />2 Perguntas IA /mês</Typography>
                </Stack>}

            {props.type === 'basic' && <Stack className="basic">
                <Typography sx={{ fontSize: 48, fontWeight: '500' }}>R$49,90</Typography>
                <Typography gutterBottom sx={{ fontSize: 16, fontWeight: '400', color: 'text.secondary' }}>Por usuário, por mês, cobrado mensalmente</Typography>
                {!props.isContracted && <Button variant="contained" onClick={() => generateLink('basic')}>Contratar agora</Button>}
                <Typography gutterBottom display={'flex'}><Check sx={{ marginRight: 1 }} />Transcrições ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check sx={{ marginRight: 1 }} />Visualize 10 reuniões /mês</Typography>
                <Typography gutterBottom display={'flex'}><Check sx={{ marginRight: 1 }} />25 Perguntas IA /mês</Typography>
            </Stack>}

            {props.type === 'pro' && <Stack className="pro">
                <Typography sx={{ fontSize: 48, fontWeight: '500' }}>R$79,90</Typography>
                <Typography gutterBottom sx={{ fontSize: 16, fontWeight: '400', color: 'text.secondary' }}>Por usuário, por mês, cobrado mensalmente</Typography>
                {!props.isContracted && <Button variant="contained" onClick={() => generateLink('pro')}>Contratar agora</Button>}
                <Typography gutterBottom display={'flex'}><Check sx={{ marginRight: 1 }} />Transcrições ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check sx={{ marginRight: 1 }} />Visualize reuniões ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check sx={{ marginRight: 1 }} />Perguntas IA ilimitadas</Typography>
            </Stack>}

            {props.type === 'enterprise' && <Stack className="enterprise">
                <Typography sx={{ fontSize: 48, fontWeight: '500' }}>Custom</Typography>
                <Typography gutterBottom sx={{ fontSize: 16, fontWeight: '400', color: 'text.secondary' }}>Condições especiais para empresas com múltiplos usuários.</Typography>
                {!props.isContracted && <Button variant="contained" onClick={() => {
                    window.open('https://wa.me/5511965690339', '_blank');
                }}>Contratar agora</Button>}
                <Typography gutterBottom display={'flex'}><Check sx={{ marginRight: 1 }} />Transcrições ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check sx={{ marginRight: 1 }} />Visualize reuniões ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check sx={{ marginRight: 1 }} />Perguntas IA ilimitadas</Typography>
                <Typography gutterBottom display={'flex'}><Check sx={{ marginRight: 1 }} />Integrações via API</Typography>
            </Stack>}
            <LinkGenModal open={open} handleClose={() => { setOpen(false) }} />
        </CardContent>
    </Card>
}
interface LinkGenModalProps {
    open: boolean;
    handleClose: Function;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
};
const LinkGenModal: FC<LinkGenModalProps> = (props: LinkGenModalProps) => {
    return <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box sx={{ ...style, width: 400 }}>
            <Typography sx={{ fontSize: 32, fontWeight: '400', marginBottom: 4 }}>Aguarde, gerando link de pagamento</Typography>
            <CircularProgress />
        </Box>
    </Modal>
}



const Cobranca: FC<CobrancaProps> = () => {
    const { user, setUser } = useGlobalState();

    return <PageContainer
        title="Cobrança"
        breadcrumbs={[]}
        style={{ maxWidth: '1280px' }}>
        <Stack direction="row" spacing={2} >
            <PlanCard planName={'Free'} type={'free'} isContracted={user?.subscription === 'free'} />
            <PlanCard planName={'Basic'} type={'basic'} isContracted={user?.subscription === 'basic'} />
            <PlanCard planName={'Pro'} type={'pro'} isRecommended={user?.subscription !== 'pro'} isContracted={user?.subscription === 'pro'} />
            <PlanCard planName={'Enterprise'} type={'enterprise'} isContracted={user?.subscription === 'enterprise'} />
        </Stack>
    </PageContainer>
}

export default Cobranca;
interface AlertLimitsProps {
    countTransc?: number
    countAsk?: number
    limitTransc?: number
    limitAsk?: number
}

function AlertLimits(props: AlertLimitsProps) {
    const progressTransc = (props.countTransc && props.limitTransc) ? props.countTransc / props.limitTransc * 100 : 0;
    const progressAsk = (props.countAsk && props.limitAsk) ? props.countAsk / props.limitAsk * 100 : 0;

    return <>
        <Alert icon={false} severity="success" sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
            <Typography sx={{ fontSize: 16, fontWeight: '400' }}>Seu plano atual.</Typography>
            <Typography sx={{ fontSize: 14, fontWeight: '400' }}>Renova em 26 de novembro</Typography>
        </Alert>
        <Typography sx={{ fontSize: 16, fontWeight: '400', color: 'text.secondary' }}>{props.countTransc ?? 0} de suas {props.limitTransc} reuniões feitas</Typography>
        <LinearProgress variant="determinate" value={progressTransc} sx={{ marginBottom: 2 }} />
        <Typography sx={{ fontSize: 16, fontWeight: '400', color: 'text.secondary' }}>{props.countAsk ?? 0} de suas {props.limitAsk} perguntas feitas</Typography>
        <LinearProgress variant="determinate" value={progressAsk} sx={{ marginBottom: 2 }} />
    </>
} 